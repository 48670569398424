@tailwind base;
@tailwind components;
@tailwind utilities;

#info-board{
    border: 1px solid black;
    position: absolute;
    max-width:90vw;
    top:10vh;
    left:5vw;
    background-image: url('../../public/img/cork-board.jpg');
}

#notice-pin{
    background: white;
    height: 80vh;
    overflow-y: scroll;
    margin-left: 5vw;
    margin-top: 1vh;
    margin-right: 5vw;
    margin-bottom: 1vh;
}

#notice-pin h1{
    font-size: x-large;
    font-weight: bolder;
}

#notice-pin h2{
    font-size: larger;
    font-weight: bold;
}

#notice-pin h3{
    font-weight: bold;
}