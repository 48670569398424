@tailwind base;
@tailwind components;
@tailwind utilities;

#adbox-left {
    border: 1px solid black;
    position: absolute;
    left: 0.1em;
    top: 8em;
    width: 24.7em;
    height: 20em;
}

.adbox-left {
    border: 1px solid black;
    position: absolute;
    left: 0.1em;
    top: 8em;
    width: 24.7em;
    height: 20em;
}

#adbox-top {
    border: 1px solid black;
    position: absolute;
    height: 7.8em;
    width: 60em;
    left: 25em;
    top: 0.1em;
}

.adbox-top {
    border: 1px solid black;
    position: absolute;
    height: 7.8em;
    width: 60em;
    top: 0.1em;
}

#postboard-wrapper {
    position: absolute;
    height: 308em;
    width: 450em;
    left: 0em;
    top: 8em;
}

#postboard {
    border: 1px solid black;
    position: absolute;
    height: 300em;
    width: 400em;
    left: 25em;
    background-image: url('../../public/img/cork-board.jpg');
}

.empty {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 100;
    width: 0px;
    height: 0px;
}

.cursor-pin {
    cursor: url('../../public/img/pinStart.png'), auto;
}

.cursor-hidden {
    cursor: none;
}

.country-state-city-pickers {
    margin-top: 0.25em;
    margin-left: 0.1em;
}

.country-state-city-pickers select {
    width: 24.4em;
}

.m03 {
    margin: 0.3em;
}

.m05 {
    margin: 0.5em;
}

.m1 {
    margin: 1em;
}

.something-msg {
    position: absolute;
    width: 20em;
    height: 20em;
    max-width: 20em;
    max-height: 20em;
    background-color: #ffea64;
    box-shadow: 0.1em 0.1em 0.1em #333;
}

.something-msg-contents {
    padding-left: 0.75em;
    padding-top: 0.5em;
    padding-right: 0.75em;
    word-wrap: break-word;
}

.pin-animation {
    animation: pushpin 1.5s;
}

.pin-animation-done {
    transform: translateY(0.2em);
    clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0 90%);
}

@keyframes pushpin {
    from {
        transform: translateY(0);
    }

    25% {
        transform: translateY(-2em);
    }

    70% {
        transform: translateY(0em);
    }

    to {
        transform: translateY(0.2em);
        clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0 90%);
    }
}



/* Button */
.button-6 {
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: .25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    min-height: 3rem;
    padding: calc(.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
}

.button-6:hover,
.button-6:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
}

.button-6:hover {
    transform: translateY(-1px);
}

.button-6:active {
    background-color: #F0F0F1;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
}

/* Tags */
.tags-container {
    display: flex;
    overflow: scroll;
    width: 100%;
    max-width: 100%;
    padding-left: 14px;
    border: 1px grey solid;
    border-radius: 5px;
    color: black
}

.tags-container input {
    width: 100%;
    min-width: 50%;
    border: none;
    border-radius: 5px;
    padding: 14px;
    padding-left: 14px;
}

.tag {
    display: flex;
    align-items: center;
    margin: 7px 0;
    margin-right: 10px;
    padding: 0 10px;
    padding-right: 5px;
    border: 1px solid orange;
    border-radius: 5px;
    background-color: orange;
    white-space: nowrap;
    color: white;
}

.tag button {
    display: flex;
    padding: 6px;
    border: none;
    background-color: unset;
    cursor: pointer;
    color: white;
}